import { dev } from '$app/environment'
import { PUBLIC_SENTRY_DSN as sentryDsn } from '$env/static/public'
import * as Sentry from '@sentry/sveltekit'
import type { RequestEvent } from '@sveltejs/kit'

const initSentry = async () => {
   if (dev) return

   Sentry.init({
      dsn: sentryDsn,
      tracesSampleRate: 1.0,
      integrations: [new Sentry.Replay()],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
   })
}
initSentry()

export const handleError = !dev
   ? Sentry.handleErrorWithSentry(({ error, event }: { error: unknown; event: RequestEvent }) => {
        console.error('[ERROR] client side:', error, event)
     })
   : async ({ error }: { error: unknown }) => {
        console.error('[ERROR] client side:', error)
     }
